import { AxiosError } from "axios";
import { Formik } from "formik";
import React, { useCallback } from "react";
import { useMutation as useReactQueryMutation } from "react-query";
import { NavLink, useNavigate } from "react-router-dom";

import axios from "@src/Clients/ReactQuery/axiosInstance";
import { initFormConfigFields } from "@src/Components/Forms/DynamicForms/initFormConfig";
import GraphQLErrors from "@src/Components/GraphQLErrors";
import { Loading } from "@src/Components/Loading/Loading";
import { ModalCloseOnButton } from "@src/Components/Modal/Modal";
import { H2, H3 } from "@src/Components/Text";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { useCanWriteOrgSettings } from "@src/Hooks/Permissions/orgSettings";
import { useToggle } from "@src/Hooks/toggle";
import { toastError } from "@src/MPNDashboard/Components/toastError";

import { QueryCallbacks } from "../mpnRequests";
import {
  NetworkForm,
  NetworkFormInner,
  networkFormSchema,
  serialiseNetworkForm,
  SuccessModal
} from "./NetworkFormComponents";
import { useLatestNetworkRegistrationChart } from "./networkRegistrationChart";

function createNewNetwork(network: object, { onComplete }: QueryCallbacks) {
  return axios
    .post("/mobile-networks/network/tmf-api/resourceInventoryManagement/v4/resource", network)
    .then(response => {
      onComplete();
      return response.data;
    });
}

export function NewNetworkForm() {
  const navigate = useNavigate();
  const orgCtx = useOrgCtx();
  const { state: showSuccess, toggle: toggleSuccess } = useToggle();

  const { chart, loading, error: infraChartError } = useLatestNetworkRegistrationChart();

  const {
    data,
    error: newNetworkError,
    mutate: createNetwork
  } = useReactQueryMutation("create-network", (newNetwork: object) =>
    createNewNetwork(newNetwork, {
      onComplete: toggleSuccess
    })
  );

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (loading) return <Loading />;
  if (!chart) return <NoChartMessage />;
  if (infraChartError) {
    return (
      <GraphQLErrors
        submitErrors={infraChartError?.graphQLErrors}
        titleMessage="Something happened while fetching for Infrastructure Block Charts"
      />
    );
  }

  const initialValues: NetworkForm = {
    fields: initFormConfigFields({ fields: chart?.config || [], weightedSort: true })
  };

  return (
    <>
      <Formik<NetworkForm>
        initialValues={initialValues}
        validateOnMount
        validationSchema={networkFormSchema}
        onSubmit={(values, formik) => {
          try {
            const serialisedValues = serialiseNetworkForm(values, chart.version, orgCtx);
            createNetwork(serialisedValues);
            formik.setSubmitting(false);
          } catch (err) {
            toastError(err);
            console.error(err);
          }
        }}
      >
        {formikProps =>
          formikProps.isSubmitting ? (
            <Loading />
          ) : (
            <>
              <NetworkFormInner
                goBack={goBack}
                submitErrors={newNetworkError as AxiosError}
                {...formikProps}
              />
              <ModalCloseOnButton show={showSuccess}>
                <SuccessModal id={data?.id} />
              </ModalCloseOnButton>
            </>
          )
        }
      </Formik>
    </>
  );
}

function NoChartMessage() {
  const orgCtx = useOrgCtx();
  const { allowed } = useCanWriteOrgSettings(orgCtx);
  return (
    <>
      <H2>Could not find an Infrastructure Block Chart</H2>
      <H3>
        No Chart able to create a Network could be found in the currently set Provisions Repository.{" "}
        {allowed ? (
          <>
            Go to the{" "}
            <NavLink to={`/app/${orgCtx}/admin/customizations`}>Customization Page</NavLink> and set
            the correct Provisions Repository.
          </>
        ) : (
          <>
            Contact your System Admin to ensure you have sufficient permissions to set the
            Provisions Repository.
          </>
        )}
      </H3>
    </>
  );
}
