/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CreateGroupBody {
  name?: string;
  superAdmin?: boolean;
  inviteMembersToOrgs?: string[];
}

export interface UpdateGroupBody {
  superAdmin?: boolean;
  inviteMembersToOrgs?: string[];
}

export interface FetchGroupResponse {
  /** @format uuid */
  id?: string;
  name?: string;
  inviteMembersToOrgs?: string[];
  superAdmin?: boolean;
}

export interface CreateUserBody {
  /** @format uuid */
  user?: string;
}

export type ListGroupPermissionsResponse = Record<
  string,
  {
    /** The name of the group */
    name?: string;
    /** The list of permissions the group has */
    permissions?: ListGroupPermissionsItem[];
  }
>;

export interface ListGroupPermissionsItem {
  /** All object types that can have their permissions assigned to a group */
  objectType?: ObjectType;
  permissions?: GroupPermission[];
}

export enum GroupPermission {
  Create = "create",
  Delete = "delete",
  Dismiss = "dismiss",
  Invite = "invite",
  Read = "read",
  Recover = "recover",
  Write = "write"
}

export interface GroupPermissionInput {
  enabled?: boolean;
  permission?: GroupPermission;
}

export type ListGroupsResponse = ListGroupsResponseItem[];

export interface ListGroupsResponseItem {
  /** @format uuid */
  id?: string;
  name?: string;
}

export interface ListGroupMembersResponse {
  members?: ListGroupMembersResponseItem[];
}

export interface ListGroupMembersResponseItem {
  /** @format uuid */
  id?: string;
}

/** All object types that can have their permissions assigned to a group */
export enum ObjectType {
  BlockChart = "BlockChart",
  Chain = "Chain",
  ChartReleaseShell = "ChartReleaseShell",
  CloudResource = "CloudResource",
  Cloudlet = "Cloudlet",
  CloudletShell = "CloudletShell",
  ConfigurationManagement = "ConfigurationManagement",
  Core = "Core",
  CoreManager = "CoreManager",
  EnvBranding = "EnvBranding",
  FederationGuest = "FederationGuest",
  FederationHost = "FederationHost",
  Gnb = "Gnb",
  Logs = "Logs",
  Network = "Network",
  Org = "Org",
  OrgSettings = "OrgSettings",
  PassiveDevice = "PassiveDevice",
  Provision = "Provision",
  RanManager = "RanManager",
  SIM = "SIM",
  Site = "Site",
  Troubleshooting = "Troubleshooting",
  User = "User"
}

export interface PostGroupPermissionsBody {
  /** All object types that can have their permissions assigned to a group */
  objectType?: ObjectType;
  permission?: GroupPermissionInput[];
}

export interface SyncOIDCUserGroupsBody {
  /** @format uuid */
  user?: string;
  groups?: string[];
}

export interface SyncPasswordUserGroupsBody {
  /** @format uuid */
  user?: string;
}

export interface Error {
  /** @format int32 */
  code?: number;
  message: string;
}
