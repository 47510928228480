import styled from "styled-components";

export const TabContent = styled.div`
  position: relative;
  padding: 0 30px 30px;
  border: 1px solid #dddddd;
`;

export const TabContentAllPadding = styled.div`
  position: relative;
  padding: 30px;
  border: 1px solid #dddddd;
`;
