import React from "react";
import styled from "styled-components";

import { Img, Main, Page, Sidebar } from "@src/Components/Navigation/NavbarStyles";
import { H1, H3 } from "@src/Components/Text";
import { HeaderWrapper } from "@src/Header";
import { useBrandingLogos } from "@src/Hooks/brandingLogos";

export const Logo = styled.div`
  width: 90px;
  height: 134px;
  margin: 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FallbackContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 50px;
`;

interface AppErrorFallBack {
  title: string;
  msg?: string;
}

export function AppErrorFallBack({ title, msg }: AppErrorFallBack) {
  const logoUrl = useBrandingLogos("nav");
  return (
    <Page>
      <Sidebar>
        <Logo>
          <Img src={logoUrl} alt="logo" />
        </Logo>
      </Sidebar>
      <HeaderWrapper />
      <Main>
        <FallbackContainer>
          <H1>{title}</H1>
          <H3>{msg}</H3>
        </FallbackContainer>
      </Main>
    </Page>
  );
}

export const appFallbackTitle = "Something went wrong when loading up the Application.";
export const appFallbackMsg =
  "Contact your System Admin to check what can be done about this issue.";
