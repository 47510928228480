import { usePermissionCheck } from "../permissionsCheck";

export function useMECDashboard() {
  const { allowed, loading } = usePermissionCheck({
    relation: "view",
    objectType: "Dashboard",
    objectId: "mec"
  });

  return { allowed, loading };
}
