import React from "react";

import TenantsIcon from "@img/groups.svg";
import MECSitesIcon from "@img/mec-sites.svg";
import ObserveIcon from "@img/observe.svg";
import OperateIcon from "@img/operate.svg";
import { NavIcons } from "@src/Components/Navigation/NavbarStyles";
import { NavItem } from "@src/Components/Navigation/NavItem";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";

export function MECNavbar() {
  const orgCtx = useOrgCtx();
  const rootPath = `/app/${orgCtx}/mec`;

  return (
    <NavIcons>
      <NavItem linkTo={rootPath + "/operate"} label="Operate" icon={OperateIcon} />
      <NavItem linkTo={rootPath + "/mec-sites"} label="MEC Sites" icon={MECSitesIcon} />
      <NavItem linkTo={rootPath + "/tenants"} label="Tenants" icon={TenantsIcon} />
      <NavItem linkTo={rootPath + "/observe"} label="Observe" icon={ObserveIcon} />
    </NavIcons>
  );
}
