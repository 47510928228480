import React, { MouseEvent, useCallback } from "react";

import { useQuery } from "@apollo/client";
import { Arrow } from "@src/Components//arrowStyles";
import { TreeSkeleton } from "@src/Components/FileTree/TreeSkeleton";
import {
  Collapsible,
  TreeDot,
  TreeItem,
  TreeWrapper
} from "@src/Components/FileTree/treeStyledComponents";
import {
  Org,
  SiteTreeItemQuery,
  SiteTreeItemQueryVariables,
  SiteTreeOrgQuery,
  SiteTreeOrgQueryVariables
} from "@src/Generated/graphql";
import { useOrgCtx } from "@src/Hooks/Context/orgCtx";
import { useCanCreateSite } from "@src/Hooks/Permissions/site";
import { NavigateSiteItem, SiteTreeProps } from "@src/Hooks/siteTree";

import { NewChildSiteInput, NewSiteInput } from "./NewSiteInput";
import { SiteTreeItem } from "./SiteTreeItem.graphql";
import { SiteTreeOrg } from "./SiteTreeOrg.graphql";

interface SiteTreeComponentProps extends SiteTreeProps {
  readOnly?: boolean;
}

export function SiteTree({ readOnly, ...siteTreeProps }: SiteTreeComponentProps) {
  const orgId = useOrgCtx();
  const { data, loading } = useQuery<SiteTreeOrgQuery, SiteTreeOrgQueryVariables>(SiteTreeOrg, {
    variables: { id: orgId },
    skip: !orgId
  });

  const org = data?.org;

  return loading ? (
    <TreeSkeleton />
  ) : (
    <TreeWrapper>
      {org ? (
        <OrgItem key={org.id} org={org} readOnly={readOnly} {...siteTreeProps} />
      ) : (
        <>Error loading Organization. Please refresh the page</>
      )}
    </TreeWrapper>
  );
}

interface OrgItemProps extends SiteTreeProps {
  org: Org;
  readOnly: boolean;
}

function OrgItem({ org, readOnly, ...siteTreeProps }: OrgItemProps) {
  const { selectTreeItem, toggleSite, state } = siteTreeProps;

  const { id: orgId } = org;

  const clickToggle = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      toggleSite(orgId);
    },
    [orgId, toggleSite]
  );

  const selected = state.selected === orgId;
  const expanded = state.expanded[orgId];
  const { allowed: canCreateSite } = useCanCreateSite(orgId);

  const emptySites = org?.sites.length === 0;
  const renderNewSite = !readOnly && (selected || (expanded && emptySites)) && canCreateSite;
  const showArrow = !readOnly || !emptySites;

  return (
    <>
      <TreeItem onClick={() => selectTreeItem(orgId, [], {})} $selected={selected}>
        {showArrow ? (
          <Arrow onClick={clickToggle} $expanded={expanded}>
            <span />
          </Arrow>
        ) : (
          <TreeDot />
        )}
        {org?.displayName}
      </TreeItem>
      <Collapsible $isOpen={state.expanded[orgId]}>
        {(org?.sites || []).map(
          s =>
            s && (
              <SiteItem
                key={s.id}
                siteId={s.id}
                ancestors={[orgId]}
                readOnly={readOnly}
                {...siteTreeProps}
              />
            )
        )}
        {renderNewSite && <NewSiteInput ancestors={[orgId]} {...siteTreeProps} />}
      </Collapsible>
    </>
  );
}

interface SiteItemProps extends SiteTreeProps {
  siteId: string;
  readOnly: boolean;
  ancestors: string[];
}

export function SiteItem({ siteId, readOnly, ancestors, ...siteTreeProps }: SiteItemProps) {
  const { selectTreeItem, toggleSite, state } = siteTreeProps;

  const { data } = useQuery<SiteTreeItemQuery, SiteTreeItemQueryVariables>(SiteTreeItem, {
    variables: { id: siteId }
  });

  const clickToggle = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      toggleSite(siteId);
    },
    [siteId, toggleSite]
  );

  const selected = state.selected === siteId;
  const expanded = state.expanded[siteId];

  const { allowed: canCreateSite } = useCanCreateSite(ancestors?.[0]);

  const emptySites = data?.site?.sites.length === 0;
  const renderNewSite = !readOnly && (selected || (expanded && emptySites)) && canCreateSite;
  const showArrow = !readOnly || !emptySites;

  const navItem: NavigateSiteItem = {
    siteId: data?.site.id
  };

  return (
    <>
      <TreeItem onClick={() => selectTreeItem(siteId, ancestors, navItem)} $selected={selected}>
        {showArrow ? (
          <Arrow onClick={clickToggle} $expanded={expanded}>
            <span />
          </Arrow>
        ) : (
          <TreeDot />
        )}
        {data?.site?.displayName}
      </TreeItem>
      <Collapsible $isOpen={state.expanded[siteId]}>
        {(data?.site.sites || []).map(
          s =>
            s && (
              <SiteItem
                key={s.id}
                siteId={s.id}
                ancestors={[...ancestors, siteId]}
                readOnly={readOnly}
                {...siteTreeProps}
              />
            )
        )}
        {renderNewSite && (
          <NewChildSiteInput ancestors={ancestors.concat(siteId)} {...siteTreeProps} />
        )}
      </Collapsible>
    </>
  );
}
