import { usePermissionCheck } from "../permissionsCheck";

export function useMPNDashboard() {
  const { allowed, loading } = usePermissionCheck({
    relation: "view",
    objectType: "Dashboard",
    objectId: "mpn"
  });

  return { allowed, loading };
}
